window.done = () ->
  window.location.href = App.redirect

$ ->
  App.checkEmail = ->
    $('.error-message').html ''
    email = $('#username').val()
    if !email
      $('#login-message').html __('enter_email')
      return false
    $.post '/user/exists', { email: email }, (data) ->
      if data.exists
        if data.has_pw
          App.showLogin(email)
        else
          App.notifyPwChange(email)
      else
        if App.isCart
          App.redirect = '/cart/checkout'
        else
          App.redirect = '/register'
        window.done()
    false

  App.notifyPwChange = (email) ->
    $('.email-step').hide()
    $('.pasword-reset-notification').show()
    $('#notification-reset').hide()
    $.post '/user/reset-password', { email: email }, (data) ->
      $('#notification-pw').hide()
      $('#notification-reset').addClass(if data.success then 'success-message' else 'error-message')
      $('#notification-reset').\
      removeClass(!if data.success then 'success-message' else 'error-message')
      $('#notification-reset').show()
      if data.success
        $('#notification-reset').html __('password_reset_success')
      else
        $('#notification-reset').html data.message
    false

  App.showLogin = (email) ->
    if $('#recaptcha').data 'show'
      $('.g-recaptcha').find('*:first-child').css({ 'margin': '0 auto' })
      $('#recaptcha').removeClass 'hidden'

    $('#show-email').html email
    $('.email-step').hide()
    $('.login-step').show()
    false

  App.login = ->
    $('.error-message').html ''
    email = $('#username').val()
    if !email
      $('#login-message').html __('enter_email')
      return false
    pass = $('#password').val()
    if !pass
      $('#password-message').html __('enter_password')
      return false
    if !$('#recaptcha').hasClass 'hidden'
      response = grecaptcha.getResponse()
      if !response
        $('#captcha-message').html __('complete_captcha')
        return false
    App.checkOldCustomer email, pass
    false

  App.checkOldCustomer = (email, password) ->
    $.post '/user/login', { email: email, password: password }, \
    (data) ->
      if data.success > 0
        if App.is_embeded
          $('#login-button').trigger("login")
        else
          window.location.href = App.redirect
      else
        if data.success == 0
          $('#login-message').html __('cart_bad_user')
        else
          $('#login-message').html __('cart_bad_pass')
        if data.info == 'show'
          $('#recaptcha').removeClass('hidden')
      return
    return

  App.resetPassword = ->
    $('#reset-message').hide()
    $('#reset-wait').show()
    email = $('#username').val()
    if email
      $.post '/user/reset-password', { email: email }, (data) ->
          $('#reset-wait').hide()
          $('#reset-message').addClass(if data.success then 'success-message' else 'error-message')
          $('#reset-message')\
          .removeClass(!if data.success then 'success-message' else 'error-message')
          $('#reset-message').show()
          if data.success
            $('#reset-message').html __('password_reset_success')
          else
            $('#reset-message').html data.message
    else
      $('#reset-message').addClass 'error-message'
      $('#reset-message').removeClass 'success-message'
      $('#reset-message').html __('enter_email')
      $('#reset-message').show()
    false

  #grecaptcha.reset()
  $('#password').on 'keypress', (e) ->
    if e.which == 13
      App.login()
    return
  $('#username').on 'keypress', (e) ->
    if e.which == 13
      App.checkEmail()
    return
  $('#email-button').on 'click', App.checkEmail
  $('#login-button').on 'click', App.login
  $('#reset-password').on 'click', App.resetPassword
  if App.is_logged
    $('.show-user').show()
    $('.show-no-user').hide()
  else
    $('.show-no-user').show()

###
  if App.isCart
    gaData =
      'event': 'bkxEvent'
      'ecommerce': 'checkout':
        'actionField':
          'step': 2
          'option': data.service
        'products': [
        ]
    for item in data.items
      gaData.ecommerce.checkout.products.push
        id: item['ean']
        name: item['title']
        price: item['price'] + (if data.service == 'exp' then item['express'] else 0)
        quantity: item['quantity']
    dataLayer.push(gaData)
###